<template>
    <vs-sidebar click-not-close position-right parent="#app" class="banner-sidebar" spacer v-model="localActiveSidebar">
        <div class="mt-6 flex items-center justify-between px-6" slot="header">
            <h4 class="text-white">{{ this.$t('Modificar banner') }}</h4>
            <feather-icon icon="XIcon" @click.stop="localActiveSidebar = false" class="cursor-pointer"></feather-icon>
        </div>


        <VuePerfectScrollbar class="scroll-area--data-list-add-new px-5">
            <form @submit.prevent="saveBanner()" id="bannerForm">
                <div class="vx-row mb-24">
                    <!-- imagen -->
                    <div class="vx-col w-full pl-16">
                        <div class="flex justify-center">
                            <img :src="banner.image" :alt="banner.name" class="rounded-lg h-64 w-96 object-contain"/>
                        </div>
                    </div>

                    <!-- nombre -->
                    <div class="vx-col w-full">
                        <label for="name">{{ this.$t('Nombre del banner') }}</label>
                        <vs-input v-model="banner.name" class="w-full" id="name"/>
                    </div>

                    <!-- Fecha de Inicio -->
                    <div class="vx-col w-full mt-5">
                        <label for="initDate">{{ this.$t('Fecha de Inicio:') }}</label><br/>
                        <sub class="text-grey italic">YYYY-MM-DD</sub>
                        <flat-pickr v-model="banner.start_date_prog" id="initDate" class="w-full"/>
                    </div>

                    <!-- hora de inicio -->
                    <div class="vx-col w-full mt-5">
                        <label for="initHour">{{ this.$t('Hora de Inicio:') }}</label><br/>
                        <sub class="text-grey italic">HH:MM:SS</sub>
                        <flat-pickr :config="configTimepicker" v-model="banner.start_hour" id="initHour" class="w-full"/>
                    </div>

                    <!-- fecha final -->
                    <div class="vx-col w-full mt-5">
                        <label for="endDate">{{ this.$t('Fecha final:') }}</label><br/>
                        <sub class="text-grey italic">YYYY-MM-DD</sub>
                        <flat-pickr v-model="banner.end_date_prog" id="endDate" class="w-full"/>
                    </div>
                        
                    <!-- hora final -->
                    <div class="vx-col w-full mt-5">
                        <label for="endHour">{{ this.$t('Hora final:') }}</label><br/>
                        <sub class="text-grey italic">HH:MM:SS</sub>
                        <flat-pickr :config="configTimepicker" v-model="banner.end_hour" id="endHour" class="w-full"/>
                    </div>
                </div>
            </form>

        </VuePerfectScrollbar>
            
        <div class=" items-center p-6" slot="footer">
            <button type="button" class="vs-component vs-button vs-button-danger vs-button-border" @click="localActiveSidebar = false">
                {{ this.$t('Cancelar') }}
            </button>

            <button type="submit" form="bannerForm" class="vs-component vs-button vs-button-info vs-button-filled float-right">
                {{ this.$t('Guardar') }}
            </button>
        </div>
    </vs-sidebar>
</template>

<script lang="ts">
import Vue                 from 'vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default Vue.extend({
    props: {
        sidebar: { required: true, type: Boolean },
        id: { required: true, type: Number },
    },
    data() {
        return {
            configTimepicker : {
                enableTime    : true,
                enableSeconds : true,
                noCalendar    : true,
            },
            banner: {}
        }
    },

    watch: {
        async id( val ) {
            if( val === 0 ) return;
            
            this.$vs.loading();

            let res = await this.$store.dispatch( 'promotions/getBannerById', val );
            this.banner = res
            this.$vs.loading.close();
                
        }
    },

    methods: {
        saveBanner() {
            this.$vs.loading();
            this.$store.dispatch('promotions/saveBanner', {
                ...this.banner,
                type : this.banner.type_banner_id,
            }).then( res => {
                this.$vs.loading.close();
                if( !res ) {
                    this.$vs.notify({
                        title    : this.$t('Ocurrió un error al guardar el banner'),
                        text: this.$t('Intente unos minutos más tarde'),
                        iconPack : 'feather',
                        icon     : 'icon-alert-circle',
                        color    : 'warning',
                        time     : 7000,
                    });
                    return;
                }

                this.localActiveSidebar = false;
            });
            
        },
    },

    computed: {
        localActiveSidebar: {
            get: function() {
                return this.sidebar;
            },
            set: function() {
                this.$store.commit('promotions/CLEAR_BANNER_DATA');
                this.$emit( 'close', false );
            }
        },
        /* banner: {
            get() {
                const store  = this.$store;
                const banner = store.state.promotions.banner;
                let bannerProxy = new Proxy( banner, {
                    set (obj, key, value) {
                        store.commit('promotions/SET_BANNER', {
                            ...obj,
                            [key]: value,
                        });    
                        return true
                    }
                });
    
                return bannerProxy;
            },
        }, */
    },

    components: {
        VuePerfectScrollbar,
        flatPickr,
    }
});
</script>

<style lang="scss" scoped>
.banner-sidebar {
    ::v-deep .vs-sidebar--background {
        z-index: 52010;
    }
    
    ::v-deep .vs-sidebar {
        position: fixed;
        z-index: 52010;
        width: 450px;
        max-width: 90vw;
        .vs-sidebar--header {
            background: #841910;
            color: #FFF;
        }
    }
}

.scroll-area--data-list-add-new {
    height: 79vh !important;
}

</style>
