<template>
    <div>
        <vx-card>
            <div class="flex justify-between mb-10">
                <h2 class="text-info">{{ this.$t('Mis banners') }}</h2>

                <vs-button color="transparent" text-color="gray" class="float-right" @click="toList()">
                    <feather-icon icon="RefreshCwIcon" :class="{ 'animate-spin': loading }"></feather-icon>
                </vs-button>
            </div>

            <div class="flex justify-end w-full">
                <!-- eslint-disable -->
                <vs-select :label="this.$t('Items por página')" v-model="maxItems" class="w-24">
                    <vs-select-item :key="index" :value="item" :text="item" v-for="item,index in maxItemsVals" />
                </vs-select>
            </div>

            <vs-table :max-items="maxItems" pagination :data="banners" search :noDataText=" $t( 'Datos no disponibles' ) ">
                <template slot="thead">
                    <vs-th>{{ this.$t('Datos inicio') }}</vs-th>
                    <vs-th>{{ this.$t('Datos fin') }}</vs-th>
                    <vs-th>{{ this.$t('Nombre del banner') }}</vs-th>
                    <vs-th>{{ this.$t('Tipo') }}</vs-th>
                    <vs-th>{{ this.$t('Categoria') }}</vs-th>
                    <vs-th></vs-th>
                </template>

                <template slot-scope="{data}">
                    <vs-tr :data="tr" v-bind:key="indextr" v-for="(tr, indextr) in data">
                        
                        <vs-td :data="data[indextr].start_date_prog">
                            {{ data[indextr].start_date_prog }} {{ data[indextr].start_hour }}
                        </vs-td>

                        <vs-td :data="data[indextr].end_date_prog">
                            {{ data[indextr].end_date_prog }} {{ data[indextr].end_hour }}
                        </vs-td>

                        <vs-td :data="data[indextr].name">
                            {{ data[indextr].name }}
                        </vs-td>

                        <vs-td :data="data[indextr].type_banner_id">
                            {{ getBannerTypeName( data[indextr].type_banner_id ) }}
                        </vs-td>

                        <vs-td :data="data[indextr].banner_category_id">
                            <span v-if="data[indextr].banner_category_id == 3">Informação</span>
                            <span v-if="data[indextr].banner_category_id == 1">Promoção</span>
                        </vs-td>

                        <vs-td :data="data[indextr].id">
                            <vs-switch style="display: inline-flex;" :value="data[indextr].status" @click="changeBannerStatus( data[indextr].id )"/>
                            <!-- boton editar -->
                            <vs-button size="small" color="transparent" @click.stop="editBanner( data[indextr].id )">
                                <img :src="require(`@/assets/icons/EditGrayIcon.svg`)" width="15px" />
                            </vs-button>

                            <!-- boton eliminar -->
                            <vs-button size="small" color="transparent" @click.stop="callModalDelete( data[indextr].id )">
                                <img :src="require(`@/assets/icons/DeleteGrayIcon.svg`)" width="15px" />
                            </vs-button>
                        </vs-td>
                    </vs-tr>
                </template>
            </vs-table>
        </vx-card>

        <edit-sidebar :id="bannerId" :sidebar="sidebar" @close="closeSidebar"/>

        <!-- modal eliminar -->
        <vs-prompt
            @accept="deleteBanners()"
            @close="closeDeleteDialog()"
            :active.sync="activePrompt"
            buttons-hidden
        >
            <div class="w-full">
                <vs-button  class="float-right" @click="closeDeleteDialog()" color="transparent" text-color="#666">
                    <feather-icon icon='XIcon'  />
                </vs-button>
            </div>
            
            <div class="w-full flex justify-center mb-5">
                <img :src="require(`@/assets/images/elements/Trash.svg`)" alt="Delete Icon" width="128px" />
            </div>
            
            <div class="text-center text-gray-400">
                <h3 class="mb-2">
                    <span>{{ this.$t('Eliminar banner seleccionado') }}</span>
                </h3>
                <p>
                    <span>{{ this.$t('¿Estás seguro que deseas eliminar definitivamente el banner seleccionado?') }}</span>
                </p>

                <p><vs-button class="mt-5 rounded-full" @click="deleteBanners()">{{ this.$t('Eliminar Definitivamente') }}</vs-button></p>
                <p><vs-button  class="mt-2 text-primary" @click="closeDeleteDialog()" color="#FFF">{{ this.$t('Cancelar') }}</vs-button></p>
            </div>
        </vs-prompt>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import EditSidebar from './SidebarEdit.vue';

export default Vue.extend({
    data() {
        return {
            loading         : false,
            bannerId        : 0,
            sidebar         : false,
            activePrompt    : false,
            bannersToDelete : [],
            maxItems        : 10,
            maxItemsVals    : [5,10,15,20,25,30,35,40,45,50,55,65,75,85,95,100],
            banners         : []
        }
    },

    async created() {
      await this.toList();
    },

    methods : {
        async toList() {
            this.loading = true;
            let res = await this.$store.dispatch('promotions/getAllBanners');
            let conct = [];
            Object.values(res.data).forEach((category) => {
              category.forEach((prod) => {
                conct.push(prod)
              });
            });
            this.banners = conct;
            this.$store.dispatch('promotions/getAllBannerTypes');
            
            /* const proms = [
                this.$store.dispatch('promotions/getAllBanners'),
                this.$store.dispatch('promotions/getAllBannerTypes'),
            ];
            Promise.all(proms)
                .then( () => {
                    this.loading = false;
                }); */
        },
        getBannerTypeName( btId=0 ) {
            const bType = this.bannerTypes.find( bType => bType.id === btId );  
            if( !bType ) return '';
            return bType.name;
        },
        editBanner( id=0 ) {
            this.bannerId = id;
            this.sidebar  = true;
            
        },
        closeSidebar( ev ) {
            this.sidebar = ev;
            if( ev ===false ) this.bannerId = 0;
        },
        closeDeleteDialog() {
            this.activePrompt    = false;
            this.bannersToDelete = [];
        },
        callModalDelete( bannerId=0 ) {
            this.bannersToDelete.push( bannerId );
            this.activePrompt = true;
        },
        deleteBanners() {
            this.$vs.loading();

            this.bannersToDelete.forEach(bannerId => {
                this.$store.dispatch( 'promotions/deleteBanner', bannerId ).then( res => res );
            });

            setTimeout( () => {
                this.$vs.loading.close();
                this.closeDeleteDialog();
                this.$vs.notify({
                    title    : this.$t('Los banners se eliminarán de forma asíncrona'),
                    iconPack : 'feather',
                    icon     : 'icon-alert-circle',
                    color    : 'primary',
                    time     : 7000,
                });
            }, 3000)
        },
        changeBannerStatus( id=0 ) {
            this.$vs.loading();
            this.$store.dispatch( 'promotions/changeBannerStatus', id)
                .then(() => {
                    this.toList()
                    this.$vs.loading.close();
                });
        },
    },

    computed : {
        bannerTypes() { return this.$store.state.promotions.bannerTypes; },
    },

    components : {
        EditSidebar,
    }
});
</script>
